import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { selectAlert } from "##/src/app/alertSlice.js";
import { selectLoading } from "##/src/app/loadingSlice.js";
import Notification from "##/src/components/alert/AlertMessage.jsx";
import Loader from "##/src/components/loading/Loader";
import AppRoutes from "##/src/pages/AppRoutes.jsx";

function App() {
  const [open, setOpen] = useState(false);
  const loading = useSelector(selectLoading);

  const alert = useSelector(selectAlert);
  useEffect(() => {
    if (alert.message) {
      setOpen(true);
    }
  }, [alert]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Notification open={open} setOpen={setOpen} />
      <AppRoutes />
    </>
  );
}

export default App;

const UNRESTRICTED_PATHS = ["/signin", "/signup", "/resetpassword"];
const OPEN_PATHS = ["forgetpassword"];
const FONTS = {
  heading: "'Roboto', sans-serif",
  body: "'Open Sans', sans-serif",
  subheading: "'Roboto', sans-serif",
};

const removeHtmlTagsAndSpaces = (htmlString) => {
  let plainText = htmlString.replace(/<[^>]*>/g, " ");
  plainText = plainText.replace(/&nbsp;/g, " ");
  const trimmedText = plainText.replace(/\s+/g, " ").trim();
  return trimmedText;
};

function debounce(func, delay) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), delay);
  };
}

export {
  debounce,
  removeHtmlTagsAndSpaces,
  UNRESTRICTED_PATHS,
  OPEN_PATHS,
  FONTS,
};

import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "##/src/context/AuthContext.jsx";

function PrivateRoute({ path, element }) {
  const { isAuthenticated, setRedirectPath } = useAuth();

  useEffect(() => {
    if (!isAuthenticated) {
      setRedirectPath(path);
    }
  }, [isAuthenticated, path, setRedirectPath]);

  return isAuthenticated ? element : <Navigate replace to="/signin" />;
}

export { PrivateRoute };

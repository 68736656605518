import { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  selectAuthenticated,
  setIsAuthenticated,
} from "##/src/app/authSlice.js";
import { setClients } from "##/src/app/clientSlice.js";
import { startLoading, stopLoading } from "##/src/app/loadingSlice.js";
import { setMe } from "##/src/app/profileSlice.js";
import { setProjects } from "##/src/app/projectSlice.js";
import { setEntries } from "##/src/app/timerSlice.js";
import { setUsers } from "##/src/app/userDetailsSlice.js";
import { setWorkspaces } from "##/src/app/workspaceSlice.js";
import FetchApi from "##/src/client.js";
import Footer from "##/src/components/footer/Footer.jsx";
import Header from "##/src/components/header/Header.jsx";
import { config } from "##/src/utility/config/config.js";
import { OPEN_PATHS, UNRESTRICTED_PATHS } from "##/src/utility/utility.js";

const AuthContext = createContext();

export default function AuthProvider({ children }) {
  const navigate = useNavigate();
  const dispatchToRedux = useDispatch();
  const [redirectPath, setRedirectPath] = useState(null);
  const isAuthenticated = useSelector(selectAuthenticated);
  const location = useLocation();
  const shouldDisplayHeaderFooter =
    isAuthenticated && !OPEN_PATHS.includes(location.pathname.split("/")[2]);

  const redirectUser = (path) => {
    return navigate(path);
  };

  useEffect(() => {
    function updateDataToRedux({
      user,
      isLoggedIn,
      projects,
      workspaces,
      entries,
      lastEntryDate,
      clients,
      users,
    }) {
      dispatchToRedux(setMe({ user }));
      dispatchToRedux(setIsAuthenticated({ isAuthenticated: isLoggedIn }));
      dispatchToRedux(setProjects({ projects: projects }));
      dispatchToRedux(setWorkspaces({ workspaces: workspaces }));
      dispatchToRedux(
        setEntries({ entries, reFetchRequired: true, lastEntryDate })
      );
      dispatchToRedux(setClients({ clients: clients }));
      dispatchToRedux(setUsers({ users: users }));
    }

    async function checkAuth() {
      dispatchToRedux(startLoading());
      try {
        const date = new Date();
        const {
          user,
          entries,
          isAuthenticated: isLoggedIn,
          projects,
          workspaces,
          clients,
          lastEntryDate,
          users,
        } = await FetchApi.fetch(`${config.api}/api/auth/isAuthenticated`, {
          credentials: "include",
          method: "POST",
          body: {
            loginDate: new Date(
              date.getUTCFullYear(),
              date.getUTCMonth(),
              date.getUTCDate()
            ),
          },
        });

        updateDataToRedux({
          user,
          isLoggedIn,
          projects,
          workspaces,
          entries,
          lastEntryDate,
          clients,
          users,
        });
        dispatchToRedux(stopLoading());
      } catch (error) {
        dispatchToRedux(stopLoading());
        window.location = "/logout";
      }
    }
    if (isAuthenticated) {
      checkAuth();
    }
  }, [dispatchToRedux, isAuthenticated, navigate]);

  useEffect(() => {
    const shouldNavigateToDashboard =
      location.pathname === "/" ||
      ((UNRESTRICTED_PATHS.includes(location.pathname) ||
        OPEN_PATHS.includes(location.pathname.split("/")[2])) &&
        isAuthenticated);

    if (shouldNavigateToDashboard) {
      return navigate("/dashboard");
    }
  }, [location.pathname, isAuthenticated, navigate]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setRedirectPath,
        redirectPath,
        redirectUser,
      }}
    >
      {shouldDisplayHeaderFooter && <Header />}
      {children}
      {shouldDisplayHeaderFooter && <Footer />}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  return useContext(AuthContext);
};

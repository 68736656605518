import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import { AdminRoute } from "##/src/components/route/AdminRoute.jsx";
import { PrivateRoute } from "##/src/components/route/ProtectedRoute.jsx";
import loadWithLazyLoader from "##/src/pages/lazyLoader/loadWithLazyLoader.js";

// Pages
const DashBoard = loadWithLazyLoader(() =>
  import("##/src/pages/DashBoard.jsx"),
);
const Clients = loadWithLazyLoader(() => import("##/src/pages/Clients.jsx"));
const Projects = loadWithLazyLoader(() => import("##/src/pages/Projects.jsx"));
const Holidays = loadWithLazyLoader(() => import("##/src/pages/Holidays.jsx"));
const Reports = loadWithLazyLoader(() => import("##/src/pages/Reports.jsx"));
const Users = loadWithLazyLoader(() => import("##/src/pages/Users.jsx"));
const Profile = loadWithLazyLoader(() => import("##/src/pages/Profile.jsx"));
const Logout = loadWithLazyLoader(() => import("##/src/pages/Logout.jsx"));
const InviteNewUser = loadWithLazyLoader(() =>
  import("##/src/pages/InviteNewUser.jsx"),
);
const InviteExistingUser = loadWithLazyLoader(() =>
  import("##/src/pages/InviteExistingUser.jsx"),
);
const ForgotPassword = loadWithLazyLoader(() =>
  import("##/src/components/ForgotPassword/ForgotPassword"),
);
const CreateNewPassword = loadWithLazyLoader(() =>
  import("##/src/components/ForgotPassword/CreateNewPasssword"),
);
const SignIn = loadWithLazyLoader(() => import("##/src/pages/SignIn.jsx"));
const SignUp = loadWithLazyLoader(() => import("##/src/pages/SignUp.jsx"));

function AppRoutes() {
  const [progress, setProgress] = useState(0);
  return (
    <>
      <LoadingBar color="#f11946" height="3px" progress={progress} />
      <Routes>
        <Route
          element={
            <PrivateRoute
              element={<DashBoard setProgress={setProgress} />}
              path="/dashboard"
            />
          }
          path="/dashboard"
        />
        <Route element={<SignIn />} path="/signin" />
        <Route element={<SignUp />} path="/signup" />
        <Route
          element={
            <AdminRoute
              element={<Clients setProgress={setProgress} />}
              path="/clients"
            />
          }
          path="/clients"
        />
        <Route
          element={
            <PrivateRoute
              element={<Projects setProgress={setProgress} />}
              path="/projects"
            />
          }
          path="/projects"
        />
        <Route
          element={<PrivateRoute element={<DashBoard />} path="/" />}
          path="/"
        />
        <Route
          element={
            <PrivateRoute
              element={<Reports setProgress={setProgress} />}
              path="/reports"
            />
          }
          path="/reports"
        />
        <Route
          element={
            <PrivateRoute
              element={<Profile setProgress={setProgress} />}
              path="/profile"
            />
          }
          path="/profile"
        />
        <Route
          element={
            <PrivateRoute
              element={<Holidays setProgress={setProgress} />}
              path="/holidays"
            />
          }
          path="/holidays"
        />
        <Route
          element={
            <AdminRoute
              element={<Users setProgress={setProgress} />}
              path="/users"
            />
          }
          path="/users"
        />
        <Route
          element={<PrivateRoute element={<Logout />} path="/logout" />}
          path="/logout"
        />
        <Route element={<InviteNewUser />} path="/invite-new" />
        <Route element={<InviteExistingUser />} path="/invite-existing" />
        <Route element={<ForgotPassword />} path="/resetpassword" />
        <Route
          element={<CreateNewPassword />}
          path="/profile/forgetpassword/:id/:token"
        />
      </Routes>
    </>
  );
}

export default AppRoutes;

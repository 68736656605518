import { Button } from "@mui/material";
import React from "react";

export const SaveButton = ({ onSave, theme }) => {
  return (
    <Button
      onClick={onSave}
      sx={{
        backgroundColor: theme?.secondaryColor,
        width: "96%",
        fontSize: "16px",
        ":hover": {
          backgroundColor: theme?.secondaryColor,
        },
      }}
      variant="contained"
    >
      Submit
    </Button>
  );
};
